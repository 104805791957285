
import { defineComponent , PropType, toRefs, computed} from 'vue'
import { IGalleryImage } from '@/interfaces/IGalleryImage'
import ImageDetailsItem from '@/components/ImageDetailsItem.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  components: {
    ImageDetailsItem,
    Footer
  },
  props: {
    imageDetails: {
      type: Object as PropType<IGalleryImage>,
      required: true
    }
  },

  setup(props) {
    const { imageDetails } = toRefs(props)    
    const description = computed(() => imageDetails?.value?.description)
    const filters = computed(() => imageDetails?.value?.filters)
    const telescope = computed(() => imageDetails?.value?.telescope)
    const mount = computed(() => imageDetails?.value?.mount)
    const camera = computed(() => imageDetails?.value?.camera)
    const type = computed(() => imageDetails?.value?.type)
    const date = computed(() => imageDetails?.value?.date)

    return {
      description,
      filters,
      camera,
      telescope,
      mount,
      type,
      date
    }
  },
})

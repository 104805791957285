
import { defineComponent } from 'vue'
import { IGalleryImage } from '@/interfaces/IGalleryImage'
import HeadingComponent from '@/components/shared/HeadingComponent.vue'
import allimages from '@/statics/images.json'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  components: {
      HeadingComponent,
      Footer
    },
  setup() {
    const galleryImages: IGalleryImage[] = allimages as IGalleryImage[]

      return {
          galleryImages
      }
    }
})

import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-32368ed2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeadingComponent = _resolveComponent("HeadingComponent")!
  const _component_ImageDetails = _resolveComponent("ImageDetails")!

  return (_ctx.image)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_HeadingComponent, { class: "mb-8" }, {
          title: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.image.name), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "border-2 mb-2",
            src: require('../assets/images/gallery/' + _ctx.image.fileName)
          }, null, 8, _hoisted_3)
        ]),
        _createVNode(_component_ImageDetails, { imageDetails: _ctx.image }, null, 8, ["imageDetails"])
      ]))
    : _createCommentVNode("", true)
}
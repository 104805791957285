<template>
  <div class="items-center">
    <div class="text-sm italic" style="color: var(--accent-color)">
      <slot name="title"></slot>
    </div>
    <div class="pl-5">
      <slot name="content"></slot>
    </div>
  </div>
</template>


import { defineComponent , onMounted, ref} from 'vue'
import { IGalleryImage } from '@/interfaces/IGalleryImage'
import HeadingComponent from '@/components/shared/HeadingComponent.vue'
import allimages from '@/statics/images.json'
import { useRoute } from 'vue-router'
import ImageDetails from '@/components/ImageDetails.vue'

export default defineComponent({
  components: {
      HeadingComponent,
      ImageDetails
    },
  setup() {
    const route = useRoute()
    const galleryImages: IGalleryImage[] = allimages as IGalleryImage[]
    let imageId: number
    const image = ref<IGalleryImage | null>(null)

    onMounted(() => {
      imageId = +route.params.id
      image.value = galleryImages.find( i => i.id === imageId) as IGalleryImage
    })

      return {
        galleryImages,
        image
      }
    }
})
